<template>
  <div>
    <div class="top">
      <div class="tableTitle">{{ title }}</div>
      <div class="btnPos">
        <v-btn color="primary" depressed small @click="openModal">Add</v-btn>
      </div>
    </div>

    <DataTable
      ref="DataTable"
      :loading="loading"
      :loadingText="loadingText"
      :tableKey="'rowSeq'"
      :footerHide="footerHide"
      :singleSelect="true"
      :showSelect="false"
      :columns="tableColumns"
      :inputData="data"
      @clickRow="clickRow"
    />

    <DialogKeyBiometrics ref="refEditModal" @saveComplete="saveComplete" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogKeyBiometrics from "@/components/commonV2/Dialog/medicalInfo/DialogKeyBiometrics.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    DataTable,
    DialogKeyBiometrics,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "key Biometrics",
      loading: false,
      loadingText: "Loading list...",
      data: [],
      tableColumns: formatService.keyBiometricsTableColumns(),
      footerHide: false
    };
  },
  watch: {
    patientData(val) {
      if (val === null) return;
      this._getData();
    },
  },
  methods: {
    openModal() {
      this.$refs.refEditModal.showModal(this.title, this.patientData, null);
    },
    clickRow(item) {
      this.$refs.refEditModal.showModal(this.title, this.patientData, item);
    },
    _getData() {
      const $h = this.$helper;
      this.data = [];
      this.patientData.endpoint = "patient/keyBiometrics";
      this.patientData.indicator = '';
      this.getDataQuery(this.patientData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        this.footerHide = res.length > 5 ? false : true;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            serviceDateLabel: $h.changeDateToStringForDp(item.serviceDate, true),
            lastIndicatorValue: item.indicatorValue + ' ' + item.indicatorUnit,
            currentIndicatorValue: item.indicatorValue + ' ' + item.indicatorUnit,
            surveySetNum:
              item.answers === undefined || item.answers === null
                ? 0
                : $h.changeStringToArr(item.answers).length,
          }));
        }
        this.data = res;
      });
    },
    saveComplete() {
      this._getData();
      this.$emit("saveComplete");
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
.top {
  display: flex;
  margin-bottom: 10px;
}
.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
</style>