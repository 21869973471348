<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <v-timeline align="start">
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :dot-color="item.color"
          :icon="item.icon"
          fill-dot
        >
          <v-card>
            <v-card-title :class="['text-h6', `bg-${item.color}`]">
              Care time : contents
            </v-card-title>
            <v-card-text class="bg-white text--primary">
              <p>
                Care Content Details
              </p>
              <v-btn :color="item.color" variant="outlined" depressed small> Button </v-btn>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    patientData: {
      type: [Object],
    },
  },
  data() {
    return {
      items: [
        {
          color: 'red-lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple-lighten-2',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green-lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo-lighten-2',
          icon: 'mdi-layers-triple',
        },
      ],
    }
  },
};
</script>
<style lang="">
</style>