<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <!-- Care Overview -->
      <EmergencyContact ref="refEmergencyContact" :patientData="patientData" />

      <div class="vGap" />

      <Encounter ref="refEncounter" :patientData="patientData" />
    </v-card>
  </v-tab-item>
</template>
<script>
import EmergencyContact from "./emergencyContact/EmergencyContact.vue";
import Encounter from "./encounter/Encounter.vue";
export default {
  components: { EmergencyContact, Encounter },
  props: {
    patientData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      setTimeout(() => {
        this.$refs.refEmergencyContact._getData();
        this.$refs.refEncounter._getData();
      }, 300);
    },
  },
};
</script>
<style lang="scss">
.vGap {
  height: 20px;
}
</style>