<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <!-- Preventive Care -->
      <VaccineHistory
        ref="refVaccineHistory"
        :patientData="patientData"
      />

      <div class="vGap" />

      <AnnualCheckup
        ref="refAnnualCheckup"
        :patientData="patientData"
      />
    </v-card>
  </v-tab-item>
</template>
<script>
import VaccineHistory from "./vaccineHistory/VaccineHistory.vue";
import AnnualCheckup from "./annualCheckup/AnnualCheckup.vue";
export default {
  components: { VaccineHistory, AnnualCheckup },
  props: {
    patientData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      this.$refs.refVaccineHistory._getData();
      this.$refs.refAnnualCheckup._getData();
    },
  },
};
</script>
<style lang="">
</style>