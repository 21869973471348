<template>
  <div>
    <div class="top">
      <div class="tableTitle">{{ title }}</div>
      <div class="btnPos">
        <!-- <v-btn color="primary" depressed small @click="openModal">Add</v-btn> -->
      </div>
    </div>

    <DataTable
      ref="DataTable"
      :loading="loading"
      :loadingText="loadingText"
      :tableKey="'rowSeq'"
      :footerHide="footerHide"
      :singleSelect="true"
      :showSelect="false"
      :columns="tableColumns"
      :inputData="data"
      @clickRow="clickRow"
    />

    <div class="vGap" />

    <div class="btnBox">
      <v-btn color="primary" depressed small @click="openSurveySet('Phq9Test')">PHQ9TEST</v-btn>
      <div class="hGap" />
      <v-btn color="primary" depressed small @click="openSurveySet('PHQ9')">PHQ9</v-btn>
      <div class="hGap" />
      <v-btn color="primary" depressed small @click="openSurveySet('Health')">Health</v-btn>
      <div class="hGap" />
      <v-btn color="primary" depressed small @click="openSurveySet('6CIT')">6CIT</v-btn>
    </div>

    <!-- 설문내용 -->
    <DialogSurveySetView ref="refEditModal" @saveComplete="saveComplete" />

    <!-- 설문(질문 + 답변) draw -->
    <v-navigation-drawer
      v-model="QuestionnaireDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="100%"
    >
      <Questionnaire-drawer
        ref="refQuestionnaire"
        @closeDrawer="QuestionnaireDrawer = false"
        @complete="saveComplete"
      >
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="closeDrawer()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </Questionnaire-drawer>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogSurveySetView from "@/components/commonV2/Dialog/medicalInfo/DialogSurveySetView.vue";
const formatService = require("@/utils/format.js");

export default {
  components: {
    QuestionnaireDrawer: () => import("./comp/Questionnaire.vue"),
    DataTable,
    DialogSurveySetView,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      title: "Survey Set Result",
      loading: false,
      loadingText: "Loading list...",
      data: [],
      tableColumns: formatService.surveySetTableColumns(),
      footerHide: false,
      QuestionnaireDrawer: false
    };
  },
  watch: {
    patientData(val) {
      if (val === null) return;
      this._getData();
    },
  },
  methods: {
    openModal() {
      this.$refs.refEditModal.showModal(this.title, this.patientData, null);
    },
    clickRow(item) {
      this.$refs.refEditModal.showModal(this.title, this.patientData, item);
    },
    _getData() {
      const $h = this.$helper;
      this.data = [];
      this.patientData.endpoint = "patient/surveySet";
      this.patientData.indicator = '';
      this.getDataQuery(this.patientData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        this.footerHide = res.length > 5 ? false : true;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            dpDateLabel: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            serviceDateLabel: $h.changeDateToStringForDp(item.serviceDate, true),
            lastIndicatorValue: item.indicatorValue + ' ' + item.indicatorUnit,
            currentIndicatorValue: item.indicatorValue + ' ' + item.indicatorUnit,
            surveySetNum:
              item.answers === undefined || item.answers === null
                ? 0
                : $h.changeStringToArr(item.answers).length,
          }));
        }
        this.data = res;
      });
    },
    saveComplete() {
      this._getData();
      this.QuestionnaireDrawer = !this.QuestionnaireDrawer;
      this.$emit("saveComplete");
    },
    openSurveySet(set) {
      this.QuestionnaireDrawer = !this.QuestionnaireDrawer;
      this.setSurveySetOpen(this.QuestionnaireDrawer);
      this.$refs.refQuestionnaire._showModal(this.patientData, set);
    },
    closeDrawer() {
      this.QuestionnaireDrawer = !this.QuestionnaireDrawer;
      this.setSurveySetOpen(this.QuestionnaireDrawer);
      this.clearUserSurvey();
    },
    ...mapActions(["getDataQuery", 'setSurveySetOpen', 'clearUserSurvey']),
  },
};
</script>
<style lang="scss">
.hGap {
  width: 5px !important;
}
.top {
  display: flex;
  margin-bottom: 10px;
}
.tableTitle {
  margin-right: auto;
  padding-top: 14px;
  font-weight: 700;
}
.btnBox {
  display: flex;
  justify-content: center;
}
</style>