<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <!-- Assessment -->
      <KeyBiometrics
        ref="refKeyBiometrics"
        :patientData="patientData"
      />

      <div class="vGap" />

      <SurveySet
        ref="refSurveySet"
        :patientData="patientData"
      />
    </v-card>
  </v-tab-item>
</template>
<script>
import KeyBiometrics from "./keyBiometrics/KeyBiometrics.vue";
import SurveySet from "./surveySet/SurveySet.vue";
export default {
  components: { KeyBiometrics, SurveySet },
  props: {
    patientData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      this.$refs.refKeyBiometrics._getData();
      this.$refs.refSurveySet._getData();
    },
  },
};
</script>
<style lang="">
</style>