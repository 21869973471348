<template>
  <v-card hover @click="clickCard">
    <v-card-text>
      <div class="d-flex justify-space-between align-center flex-wrap mb-4">
        <div class="d-flex align-center">
          <img
            class="mr-md rounded-circle mr-2"
            height="44"
            width="44"
            :src="avatar"
          />
          <div>
            <p class="ma-0">
              <a
                href="#"
                class="text--primary font-weight-medium mb-1 hover:info--text"
              >
                {{ title }}
              </a>
            </p>
            <p class="text--disabled caption ma-0">
              {{ subTitle }}
            </p>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="d-flex justify-space-between align-center flex-wrap mt-4">
      </div>
      <div>
        <p class="text--disabled caption ma-0 pa-0 ml-3">
          <b>{{ dueDateStart }} ~ {{ dueDateEnd }}</b>
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    // Floating Button icon Color
    avatar: {
      type: String,
      default: require("@/assets/images/avatars/004-bald.svg"),
    },
    title: {
      type: String,
      default: "Timothy Clarkson",
    },
    subTitle: {
      type: String,
      default: "Vue Developer",
    },
    dueDateStart: {
      type: String,
      default: "",
    },
    dueDateEnd: {
      type: String,
      default: "",
    },
    footerBtn: {
      type: String,
      default: "Medical Info",
    },
    footerBtnTwo: {
      type: String,
      default: "Profile",
    },
    buttonColorOne: {
      type: String,
      default: "",
    },
    buttonIconOne: {
      type: String,
      default: "",
    },
    buttonIconTwo: {
      type: String,
      default: "",
    },
    buttonColorTwo: {
      type: String,
      default: "",
    },
    buttonIconThree: {
      type: String,
      default: "mdi-google",
    },
    buttonColorThree: {
      type: String,
      default: "",
    },
    progressLinearColor: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
    };
  },
  methods: {
    clickCard() {
      this.$emit('clickCard')
    },
  },
};
</script>
