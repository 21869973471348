<template>
  <v-tab-item :transition="false">
    <v-card flat class="editorPadding">
      <!-- Medical Information -->
      <DiagnosisCategory
        ref="refDiagnosisCategory"
        :patientData="patientData"
      />

      <div class="vGap" />

      <Medication ref="refMedication" :patientData="patientData" />

      <div class="vGap" />

      <MedicationAllergy
        ref="refMedicationAllergy"
        :patientData="patientData"
      />

      <div class="vGap" />

      <FoodAllergy ref="refFoodAllergy" :patientData="patientData" />
    </v-card>
  </v-tab-item>
</template>
<script>
import DiagnosisCategory from "./diagnosisCategory/DiagnosisCategory.vue";
import Medication from "./medication/Medication.vue";
import MedicationAllergy from "./allergy/MedicationAllergy.vue";
import FoodAllergy from "./allergy/FoodAllergy.vue";
export default {
  components: { DiagnosisCategory, Medication, MedicationAllergy, FoodAllergy },
  props: {
    patientData: {
      type: [Object],
    },
  },
  methods: {
    _infoUpdate() {
      this.$refs.refDiagnosisCategory._getData();
      this.$refs.refMedication._getData();
      this.$refs.refMedicationAllergy._getData();
      this.$refs.refFoodAllergy._getData();
    },
  },
};
</script>
<style lang="">
</style>