<template>
  <div>
    <v-row>
      <v-col md="12">
        <template>
          <v-row>
            <v-col
              v-for="(item, index) in data"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <list-card
                :avatar="item.cardImg"
                :title="item.cardTitle"
                :sub-title="item.cardSubTitle"
                :dueDateStart="item.dueDateStartLabel"
                :dueDateEnd="item.dueDateEndLabel"
                :progress-linear-color="item.progressLinearColor"
                @clickCard="clickCard(item)"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import listCard from "./CarePlanCardObject.vue";
export default {
  components: { listCard },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    columns: {
      default: () => [],
      type: Array,
    },
    customColumn: {
      default: "",
      type: String,
    },
  },
  methods: {
    clickCard(item) {
      // console.log("", item);
      this.$emit("clickCard", item);
    },
  },
};
</script>

<style lang="scss">
.photoBox {
  text-align: center;
}
.iconStyle {
  font-size: 60px !important;
  color: rgb(210, 210, 210) !important;
}
.regTag {
  color: darkgray;
}
</style>