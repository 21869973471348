<template>
  <v-tab-item :transition="false">
    <v-form ref="form" class="ACPBox">
      <!-- 추가버튼 -->
      <v-row @click="clickAdd">
        <v-col cols="1" class="ma-0 pa-0" style="text-align: right">
          <v-btn large fab color="primary">
            <v-icon class="primary"> mdi-plus </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="11" class="ma-0 pa-0">
          <v-card-text class="card-title ma-0">
            Action Care Plan Add
          </v-card-text>
        </v-col>
      </v-row>
      <!-- 수행완료/중인 케어플랜 -->
      <v-row class="_top">
        <v-col cols="12">
          <CarePlanCard :data="carePlanItems" @clickCard="clickCard"/>
        </v-col>
      </v-row>
    </v-form>

    <!-- 추가 -->
    <DialogActionCarePlanAdd ref="refDialogActionCarePlanAdd" @saveComplete="saveComplete"/>
  </v-tab-item>
</template>
<script>
import { mapActions } from "vuex";
import DialogActionCarePlanAdd from "@/components/commonV2/Dialog/DialogActionCarePlanAdd.vue";
import CarePlanCard from "./comp/CarePlanCard.vue";
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    DialogActionCarePlanAdd,
    CarePlanCard,
  },
  props: {
    patientData: {
      type: [Object],
    },
  },
  data() {
    return {
      carePlanItems: [],
    };
  },
  methods: {
    _infoUpdate(patientData) {
      this.patientData = patientData;
      console.log("patientData : ", patientData);
      this.getData();
      setTimeout(() => {
        // this.$refs.refCareProgramConsent.getConsentData();
      }, 300);
    },
    getData() {
      this.carePlanItems = [];
      const $h = this.$helper;
      this.patientData.endpoint = "patient/medicationInfo/actionCarePlanList";
      this.getDataQuery(this.patientData).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            cardTitle: `${this.patientData.nameFull}(${this.patientData.gender})`,
            cardSubTitle: `${this.patientData.age} / ${this.patientData.serviceType}`,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            dueDateStartLabel: $h.changeDateToStringForDp(item.dueDateStart),
            dueDateEndLabel: $h.changeDateToStringForDp(item.dueDateEnd),
            // isUserTable: true,
            // nameFull: `${$h.changeNameFullDp(item.fname, item.mname, item.lname)}(${item.user_id})`,
            // genderShort: $h.changeGenderShortForDp(item.gender),
            // age: $h.changeDOBToAgeForDp(item.dob),
            // ccmCareTimeLabel: $h.secondsToTime(item.ccmcareTime, true),
            // useYNLabel: $h.memberTypeLbl(item.activated),
            // signupPathLabel: $h.signupPathLabel(item.signupPath),
            // isPhoto: $h.isPhotoChecker(item.userPhotoImg),
            // dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
            // patientMemo: $h.patientMemo(item.memo),
            // cardTitle: `${item.name}(${item.gender})`,
            // cardSubTitle: `${$h.changeDOBToAgeForDp(item.dob)} / ${item.serviceType}
            // -> ${$h.secondsToTime(item.ccmcareTime, true)}`,
            // cardImg: this.makeAvatarImg(item.userPhotoImg, $h.isPhotoChecker(item.userPhotoImg), item.gender),
            // cardRegistrationDate: $h.changeDateToStringForDp(item.registrationDate, true)
          }));
        }
        console.log("carePlanData", res);
        this.carePlanItems = res;
        // {
        //   this.carePlanData = modelService.patientActionCarePlanModel();
        //   console.log("데이터 없음 / this.carePlanData", this.carePlanData);
        //   return;
        // }
        // resP.dpDateLabel = this.$helper.changeDateToForUpdateDp(resP.regDate, resP.updDate);
        // this.carePlanData = resP.data;
        // console.log("this.carePlanData", this.carePlanData);
      });
    },
    clickCard(item) {
      console.log("clickCard", item);
      this.$refs.refDialogActionCarePlanAdd.Open(this.patientData, item);
    },
    clickAdd() {
      console.log("clickAdd");
      this.$refs.refDialogActionCarePlanAdd.Open(this.patientData, null);
    },
    saveComplete() {
      this.getData();
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.ACPBox {
  padding: 20px;
  ._top {
    align-items: flex-start;
  }
}
</style>